import React, { FC, ReactElement } from 'react';

import Container from 'layout/Container';
import Button from 'components/elements/Button';
import Typography from 'components/elements/Typography';
import ProductCarousel from 'components/product/ProductCarousel';
import ProductDetails from 'components/product/ProductDetails';
import ProductFeatures from 'components/product/ProductFeatures';
import SecondaryFeatures from 'components/product/SecondaryFeatures';
import BuyPopup from 'components/sections/BuyPopup';

import { IPropsProductBanner } from './models';

import './ProductBanner.scss';

const ProductBanner: FC<IPropsProductBanner> = ({
  title,
  productDetails,
  productFeatures,
  secondaryFeatures,
  buyCTA,
  buyMikMak,
  buyPopup,
  additionalProductImages,
  defaultProductImage,
  ean,
  hsafsa,
  globalData,
}): ReactElement | null => (
  <Container data-testid="ProductBanner" className="product-banner">
    <div className="product-banner__images">
      <ProductCarousel
        items={additionalProductImages}
        defaultProductImage={defaultProductImage}
        isFirstViewport
        globalData={globalData}
        generalImageAlt={title}
      />
    </div>
    <div className="product-banner__info">
      {title ? (
        <Typography
          customClass="product-banner__title"
          customColor="black"
          data={[
            {
              text: title,
              removeParentTag: true,
              alignment: 'left',
              tag: 'h1',
            },
          ]}
        />
      ) : null}
      {productFeatures?.length ? <ProductFeatures data={productFeatures} variant="row" /> : null}
      <SecondaryFeatures data={secondaryFeatures} />
      {buyCTA?.length ? (
        <div className="product-banner__buy-cta-wrapper">
          <Button data={buyCTA} />
        </div>
      ) : null}

      {process.env.GATSBY_MIKMAK_APP_ID && ean && buyMikMak?.length ? (
        <div className="product-banner__buy-cta-wrapper">
          <Button data={buyMikMak} className="swn-tag-wtb-btn" ean={ean} />
        </div>
      ) : null}

      {buyPopup?.length ? <BuyPopup data={buyPopup} /> : null}

      {hsafsa?.length ? (
        <div className="product-det-tags">
          <div className="tag-green">
            <i className="far fa-check-circle" />
            {hsafsa}
          </div>
        </div>
      ) : null}
      <ProductDetails data={productDetails} title={title}/>
    </div>
  </Container>
);

export default ProductBanner;
